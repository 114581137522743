import React, { FC, ReactNode } from 'react';
import { Box, Card, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import ReadButton from './ReadButton';

interface CardProps {
  title: string;
  text?: string;
  icon: ReactNode;
  image: string;
  link?: string;
  isCareerCard?: boolean;
  mainPageLink?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(0, 5, 7),
    backgroundImage: ({ image }: Partial<CardProps>) => `url(${image})`,
    boxShadow: 'inset 1px -380px 205px -99px rgba(0, 0, 0, 0.8)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
    borderRadius: 0,
    maxHeight: '100%',
    position: 'relative',
    willChange: 'box-shadow',
    transition: ' 250ms ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: 'inset 0px -578px 239px -291px rgba(125, 43, 0, 0.8)',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 368,
    },
  },
  textColor: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
}));

const ExpertiseCard: FC<CardProps> = (props) => {
  const { title, text, link, icon, image, mainPageLink, isCareerCard = false } = props;
  const classes = useStyles({ image });
  const isSmallUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Card className={classes.card}>
      <Box mb={15}>{icon}</Box>
      <Box mb={2.4}>
        <Typography className={classes.textColor} variant="h5">
          {title}
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography className={classes.textColor} variant="body2">
          {text}
        </Typography>
      </Box>
      <Box display={isSmallUp ? 'flex' : 'block'}>
        {!!link && (
          <Box
            mr={isSmallUp ? 2 : 0}
            role="button"
            tabIndex={0}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.stopPropagation();
                window.location.href = link;
              }
            }}
          >
            <ReadButton href={link} text={isCareerCard ? 'Discover' : 'Read More'} whiteButton />
          </Box>
        )}
        {!!mainPageLink && (
          <Box
            mt={isSmallUp ? 0 : 2}
            role="button"
            tabIndex={0}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.stopPropagation();
                window.location.href = mainPageLink;
              }
            }}
          >
            <ReadButton href={mainPageLink} text={'View Page'} whiteButton />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ExpertiseCard;
